/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~ngx-toastr/toastr.css";
/* @import "~bootstrap/dist/css/bootstrap.css"; */

button:disabled {
  background-color: grey !important;
}

:root {
  /* --proctees-template-rows: 50% 50%;
  --proctees-template-columns: 33.3% 33.3% 33.3%; */
  // --proctee-height: 320px;
  // --proctee-width: 320px;
}
